import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../container/main'
import SEO from '../components/seo'

import Container from '../components/container'
import ContentHeroNew from '../components/content-hero-new'
import ContentInlineNav from '../components/content-inline-nav'
import ContentBlockLarge from '../components/content-block-large'
import ContentBlockSmall from '../components/content-block-small'
import ContentBannerWithCTA from '../components/content-banner-with-cta'

// import IconPasswordManager from '../assets/images/password-manager.png'
// import IconOrganizeYourDailyAgenda from '../assets/images/organize-your-daily-agenda.png'
// import IconFindEverythingCrossApp from '../assets/images/find-every-thing-cross-app.png'
// import IconWorkWithDataCrossApp from '../assets/images/work-with-data-cross-app.png'
// import IconNotificationsAtAGlance from '../assets/images/notifications-at-a-glance.png'

// import IconAllAppsOneSolution from '../assets/images/all-apps-one-solution.png'
// import IconMultiDevice from '../assets/images/placeholder-landscape.png'
// import IconManageSaasUserInOnePlace from '../assets/images/manage-saas-users-in-one-place.png'
// import IconShareAnyAppWithoutEffort from '../assets/images/share-any-app-without-effort.png'
// import IconAnalyzeOptimizeSaasUsage from '../assets/images/analyze-optimize-saas-usage.png'

import IconUsers from '../assets/images/ic_users.svg'

const IndexPage = ({ data }) => (
  <MainContainer>
    <SEO title="Basaas Workplace" />
    <Container>
      <ContentHeroNew
        kicker="Basaas Chrome Extension"
        headline="Productivity superpowers for your browser"
        actionLinkText={
          <React.Fragment>
            <svg className="icon" width="34" height="34" viewBox="0 0 34 34" fill="none">
              <path
                className="fill-color"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 3C20 1.34315 18.6569 0 17 0C15.3431 0 14 1.34315 14 3V6H8C6.89543 6 6 6.89543 6 8V14H9C10.6569 14 12 15.3431 12 17C12 18.6569 10.6569 20 9 20H6V26C6 27.1046 6.89543 28 8 28H14V25C14 23.3431 15.3431 22 17 22C18.6569 22 20 23.3431 20 25V28H26C27.1046 28 28 27.1046 28 26V20H31C32.6569 20 34 18.6569 34 17C34 15.3431 32.6569 14 31 14H28V8C28 6.89543 27.1046 6 26 6H20V3Z"
                fill="#3366FF"
              ></path>
            </svg>
            Install Chrome Extension
          </React.Fragment>
        }
        actionLinkIsExternal
        actionLink="https://chrome.google.com/webstore/detail/basaas-extension/dfnefpadkibojlglpobnjbphlaojobfg?utm_source=basaas-com&utm_medium=hero&utm_campaign=extension&utm_content=extension-lp"
        media={<StaticImage src="../assets/images/extension-sidebar-popup.png" alt="Optimize your app management" placeholder="blurred" />}
      />
    </Container>

    {/* Block 1 */}
    <Container withBackground style={{ padding: '80px 0' }}>
      <ContentBlockLarge
        kicker="Omnipresent sidebar in your browser"
        headline="A sidebar with all your apps available on every website"
        description="Find your sidebar everywhere in your browser, no matter which website you’re on. Launch your apps with just one click and get notifications from your apps."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/extension-sidebar.png" alt="Optimize your app management" placeholder="blurred" />}
      />
      <Container columns={3} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="Browser-wide sidebar"
          description="Get a sidebar to acess your apps everywhere in your browser. If you don’t need it at some point, just hide it with one click."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/your-sidebar-in-the-browser.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Launch all apps with one click"
          description="Start your workday right and launch all your apps in your sidebar at once with just one click. Everything is available immediately."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/quick-launch.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="See notifications of your apps"
          description="Stay up to date with notifications and keep all your apps always in view. With the sidebar, you’ll get all your notifications in one place."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/notifications-at-a-glance.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
      </Container>
    </Container>

    {/* Block 2 */}
    <Container withBackground style={{ padding: '80px 0' }}>
      <ContentBlockLarge
        kicker="Navigate your apps on every website"
        headline="Access your apps across the web with ease"
        description="With the handy popup, you can open all your apps at any time and save a lot of time. Use the search and find all your apps in the blink of an eye."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/extension-popup.png" alt="Optimize your app management" placeholder="blurred" />}
        reverse
      />
      <Container columns={3} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="Find and launch apps"
          description="Use the search in the popup to find your apps, even the ones you haven’t added to your sidebar. Access all your apps with just one click."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/access-your-apps-everywhere.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Notifications from your apps"
          description="Find all notifications from your apps in one place in your popup and keep track of everything happening in your apps."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/notifications-at-a-glance.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Manage your passwords"
          description="Use the password manager and manage all your logins right where you work. Simply log in to your apps and benefit from high security."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/password-manager.png" alt="Work across apps everywhere" placeholder="blurred" />}
          comingSoon
        />
      </Container>
    </Container>

    {/* Block 3 */}
    <Container withBackground style={{ padding: '80px 0' }}>
      <ContentBlockLarge
        kicker="Find everything you need in My Day"
        headline="All your events and tasks just a new tab away"
        description="Stay on top of your workload, events and apps with the new tab My Day view. With your events and focus tasks in mind, you can organize your day perfectly and always know what to do next."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/extension-new-tab.png" alt="Optimize your app management" placeholder="blurred" />}
      />
      <Container columns={3} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="Everything to focus about"
          description="Find everything you need to focus about in your new tab. Always keep on track about your next events or your tasks."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/all-you-need-in-a-new-tab.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Attend upcoming events"
          description="You can see your next events from integrated apps in your calendar. If it's an online meeting, you can join with just one click."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/organize-your-daily-agenda.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Check your focus tasks"
          description="All your focus tasks are displayed in a new tab. You always have the perfect overview of your most important tasks."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/check-focus-tasks.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
      </Container>
    </Container>

    {/* Block 4 */}
    <Container withBackground style={{ padding: '80px 0' }}>
      <ContentBlockLarge
        kicker="Create new tasks everywhere"
        headline="Create tasks on every website in every app"
        description="No matter on which website - create new tasks anywhere in your browser with a shortcut or a right-click. Add tasks to projects or task management apps like ClickUp, Asana and many more without the need to open these apps."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/extension-create-task-modal.png" alt="Optimize your app management" placeholder="blurred" />}
        reverse
      />
      <Container columns={3} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="A new task in just one click"
          description="Right click to create a new task. You can create new tasks with a right-click on every website and never have to leave the website you’re on."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/tasks-create-context-menu.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Use a shortcut to create tasks"
          description="Use a handy shortcut to create tasks everywhere in your browser. Add tasks to Basaas or your connected apps."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/create-task-shortcuts.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Add tasks to integrated apps"
          description="Connect your task management apps with Basaas and directly add new tasks to your integrated apps on every website."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/manage-tasks-across-all-apps.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
      </Container>
    </Container>

    {/* Block 5
    <Container withBackground style={{ padding: '80px 0' }}>
      <ContentBlockLarge
        kicker="A password manager for everyone in the team"
        headline="Simplify access to your apps with the password manager"
        description="A password manager for everyone in your team not only provides more security, but also saves you a lot of time by allowing you to log into all apps in no time."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/placeholder-landscape.png" alt="Optimize your app management" placeholder="blurred" />}
      />
      <Container columns={3} style={{ marginTop: '-48px' }}>
        <ContentBlockSmall
          headline="Easy login into your apps"
          description="Simplify the access to your apps with the built-in password manager. Save your passwords and easily sign into your apps."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/password-manager.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="More security for your login"
          description="You can see your next events from integrated apps in your calendar. If it's an online meeting, you can join with just one click."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/feature-icons/proven-enterprise-security.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
        <ContentBlockSmall
          headline="Organize logins with ease"
          description="All your Focus tasks are displayed in a new tab. You always have the perfect overview of your most important tasks."
          // actionLinkText="Learn more"
          // actionLink="/workplace"
          media={<StaticImage src="../assets/images/placeholder-landscape.png" alt="Work across apps everywhere" placeholder="blurred" />}
        />
      </Container>
    </Container> */}

    <Container style={{ padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage

// export const query = graphql`
//   query {
//     placeholderLandscape: file(relativePath: { eq: "placeholder-landscape.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderPortrait: file(relativePath: { eq: "placeholder-portrait.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderSquare: file(relativePath: { eq: "placeholder-square.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }
//   }
// `
